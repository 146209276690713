<template>
  <div class="page-wrapper">
    <div v-if="!user && !allowed">
      <form class="form" method="post" @submit.prevent>
        <fieldset class="form__field">
          <label for="code" class="form__label">Code d'accès</label>
          <input type="password" id="code" placeholder="Code d'accès" class="form__input" v-model="code">
        </fieldset>
      </form>
    </div>

    <div v-else>
      <div v-if="events.length" class="item-container">
        <CalendarItemList
          v-if="events"
          :events="events"
          action 
          @alert="alertUsers"
          @late="alertLateUsers"
          @skip="skip"
          :filter="filter"
        >
          <h1 class="calendar__container__title calendar__container__title--center">
            {{ day }}
          </h1>

          <!-- <div class="calendar__container__actions">
            <button class="btn" @click="filter = !filter">
              <span v-if="filter">Afficher tout</span>
              <span v-else>Afficher le prochain créneau</span>
            </button>
          </div> -->
        </CalendarItemList>
      </div>
      
      <p v-else>
        Pas de créneau disponible
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CalendarItemList from './CalendarItemList';
import { arrActions } from '@/mixins';

export default {
  components: { CalendarItemList },

  mixins: [ arrActions ],

  data() {
    return {
      filter: false,
      code: null,
    };
  },

  async created() {
    await this.getEvents();
  },

  computed: {
    ...mapGetters({
      baseurl: 'app/baseurl',
      userBicycle: 'authentication/userBicycle',
      eventsByUserId: 'calendar/eventsByUserId',
      eventsOrdered: 'calendar/eventsOrdered',
      user: 'authentication/user',
    }),

    day() {
      return new Date().toLocaleString('fr-fr', { 
        weekday: 'long',
      });
    },

    today() {
      return new Date().toLocaleString('fr-fr', { 
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
    },

    events() {
      // if (!this.user) return [];

      return this.eventsByUserId({
        user: 'michel', // TODO: ... replace
        date: this.today,
      });
    },

    allowed() {
      return this.code === process.env.VUE_APP_ALERTCODE;
    },
},

  methods: {
    ...mapActions({
      getEvents: 'calendar/fetchEvents',
      updateStorageEvent: 'calendar/updateEvent',
    }),

    async updateEvent(event, emit = false) {
      await this.updateStorageEvent({ ...event, late: true });

      if (emit) {
        this.$nextTick(() => {
          this.$emit('sent');
        });
      }
    },

    async alertUsers({ event, receivers }) {
      // adresse seule
      const { status } = await axios.post(
        `${this.baseurl}/sms/send`,
        {
          message: `Nous arrivons bientôt ${event.location}`, 
          receivers,
        }
      );

      if (status === 200) {
        this.updateEvent(event, true);
      }
    },

    async alertLateUsers({ event, receivers }) {
      // const { status } = await axios.post(
      await axios.post(
        `${this.baseurl}/sms/send`,
        {
          message: 'Désolés, nous aurons plus ou moins 10 minutes de retard.',
          receivers,
        }
      );

      this.$nextTick(() => {
        this.updateEvent({ ...event, late: true }, true);
      });

    },

    async skip({ event }) {
      this.updateEvent(event);
    }
  },
};
</script>
