<template>
  <div class="page-wrapper">
    <CalendarView @sent="sent" />

    <Toaster />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CalendarView from '../components/Calendar/CalendarView';
import {Toaster} from '../components/Utils';

export default {
  components: {CalendarView, Toaster},

  data() {
    return {
      current: 0,
    };
  },

  created() {
    this.syncClients();
  },

  methods: {
    ...mapActions({
      setToaster: 'toaster/setToaster',
      syncClients: 'clients/setClients',
    }),

    changeCurrent() {
      this.current = this.current === 1 ? 0 : 1;
    },

    sent() {
       this.setToaster({
        message: `Le message a été envoyé`,
        type: 'message',
        time: Date.now(),
      });
    },
  },
}
</script>
